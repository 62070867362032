.machineHeaderLogin{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    /* margin-top: -0.8rem; */
}
.machineimagelogoLogin{
    height: 3rem;
    width: 7rem;
}
.roomNameLogin{
    font-size: 1rem;
}
.companydemoNameLogin{
    font-size: 0.8rem;
}

.machineheadingLogin{
    font-size: 0.8rem;
    color: white;
    width: auto;
    /* background-color: red; */
    margin-left: 6rem;
}
.datetimeupdatedLogin{
    margin-right: 2rem;
}
.boldmachineheadingLogin{
    color: white;
}
@media screen and (max-width:750px) {
    .machineHeaderLogin{
        flex-wrap: wrap;
    }
    .datetimeupdatedLogin{
        display: none;
    }
}
.demoMainLogin{
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    text-align: center;
    /* align-self: center; */
}