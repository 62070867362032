.machineHeader{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    /* margin-top: -0.8rem; */
}
.machineimagelogo{
    height: 2rem;
    width: 8rem;
}
.roomName{
    font-size: 0.9rem;
}

.machineheading{
    font-size: 0.8rem;
    color: gray;
    /* width: 30%; */
}
.datetimeupdated{
    margin-right: 2rem;
}
.boldmachineheading{
    color: black;
}
@media screen and (max-width:750px) {
    .machineHeader{
        flex-wrap: wrap;
    }
    .datetimeupdated{
        display: none;
    }
}