/* .machineMainContainer{
    padding: 4rem 3rem;
} */
.mainWithFooter {
    /* height: 100%; */
    position: relative;
    color: white;
    height: 100vh;
    width: 100%;
    background: linear-gradient(var(--backDegree), var(--back1Color), var(--back2Color) , var(--back2Color), var(--back2Color), var(--back3Color));
    background-size: 100% 100%;
    /* animation: gradient-animation 15s ease-in-out infinite; */
}

.machineMainContainer {
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    min-height: calc(100vh - 40px);
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}


.machineNameID,
.datetime {
    font-size: 11px;
    padding:  0.2rem;
}

.centerName {
    text-align: center;
    align-self: center;
    color: white;
}

.flowarea {
    padding: 0.2rem;
    font-size: 12px;
}

.machineMainContainer .machineCard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* display: grid;
    grid-template-columns: repeat(5, 2fr); */
    /* flex-wrap: wrap; */
    width: 90%;
    margin: 0 auto;
    padding-top: 5rem;
}

.machineMainContainer .machinemain {
    /* background-color: rgb(228, 220, 199); */
    display: flex;
    flex-direction: column;
    height: 5rem;
    width: 8rem;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    margin: 5px;
    text-align: start;
    font-size: 12px;
}

.gatherChart {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.gaugechartareamachine {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
    /* background-color: var(--backOpacityColor); */
    width: 18rem;
    margin: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 5px;
    background-color: #3c3f431c;

}


.gaugechartareamachine:hover {
    color: white;
}



@media screen and (max-width: 900px) and (min-width:750px) {
    .machineMainContainer .machineCard {
        grid-template-columns: repeat(3, 2fr);
        width: 90%;
    }

    .machineMainContainer {
        height: auto;
    }
}

@media screen and (max-width: 750px) and (min-width:600px) {
    .machineMainContainer .machineCard {
        grid-template-columns: repeat(3, 2fr);
        width: 90%;
    }

    .machineMainContainer {
        height: auto;
    }
}

@media screen and (max-width: 600px) and (min-width:450px) {
    .machineMainContainer .machineCard {
        grid-template-columns: repeat(2, 2fr);
        width: 90%;
    }

    .machineMainContainer {
        height: auto;
    }
}

@media screen and (max-width: 450px) {
    .machineMainContainer .machineCard {
        grid-template-columns: repeat(1, 2fr);
        width: 90%;
    }

    .machineMainContainer {
        height: auto;
    }
}

.loader {
    /* height: 100vh; */
    width: 100%;
    /* display: flex; */
    /* align-items: flex-start;
    justify-content: flex-start; */
}